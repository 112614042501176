<template>
  <div style="width: 16px" class="mr-1">
    <Button
      type="button"
      style="width: 16px; overflow: visible"
      icon="pi pi-filter"
      :class="{ 'fil-btn': modelValue }"
      class="p-button-sm p-button-text p-0"
      @click="toggle"
    />
    <OverlayPanel ref="filters" :showCloseIcon="true" ariaCloseLabel="">
      <div>
        <div class="p-inputtext-sm p-inputgroup m-1">
          <InputText
            @keyup.enter="showValue"
            @input="isSearcDisabled"
            type="search"
            v-model.trim="value"
            :placeholder="placeholderName"
          />
          <Button
            :disabled="isSearcDisables"
            @click="showValue"
            icon="pi pi-search"
            class="p-button"
            v-tooltip="'Поиск'"
          />
        </div>
        <small v-if="dsc">Можно ввести несколько значений через запятую</small>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
export default {
  name: "FilterSearch",
  props: {
    placeholderName: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    dsc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.modelValue,
      // value: this.valueTrn,
      isSearcDisables: true,
    };
  },
  methods: {
    isSearcDisabled() {
      if (this.value) {
        this.isSearcDisables = false;
      } else {
        this.isSearcDisables = true;
        this.$emit("update:modelValue", null);
      }
    },
    showValue() {
      this.$emit("update:modelValue", this.value);
      this.toggle();
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
  },
  created() {
    this.value ? (this.isSearcDisables = false) : (this.isSearcDisables = true);
  },
  computed: {
    //   modelValue: {
    //   get () { return this.modelValue },
    //   set (value) { this.$emit('update:modelValue', value) },
    // }
  },
  watch: {},
};
</script>

<style lang="scss">
.fil-btn {
  overflow: visible;
  .pi.pi-filter.p-button-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    &::after {
      content: "";
      position: absolute;
      margin-top: -4px;
      margin-right: -4px;
      width: 8px;
      height: 8px;
      background: var(--base);
      border-radius: 50%;
    }
  }
}
</style>