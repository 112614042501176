<template>
  <div style="width: 16px" class="mr-1">
    <Button
      style="width: 16px; overflow: visible"
      type="button"
      icon="pi pi-filter"
      :class="{ 'fil-btn': valueFrom > 0 || valueTo > 0 }"
      class="p-button-sm p-button-text p-0"
      @click="toggle"
    />

    <OverlayPanel ref="filters" :showCloseIcon="true">
      <div>
        <!-- <small class="text-center block mb-2"
          >Оба поля обязательны к заполнению</small
        > -->
        <div style="width: 300px" class="p-inputtext-sm p-inputgroup m-1">
          <InputText
            @keyup.enter="showValue"
            @input="isSearcDisabled(e)"
            v-model.number="vFrom"
            placeholder="от"
          />

          <InputText
            @keyup.enter="showValue"
            @input="isSearcDisabled(e)"
            v-model.number="vTo"
            placeholder="до"
          />

          <Button
            :disabled="isSearcDisables"
            @click="showValue"
            icon="pi pi-search"
            class="p-button"
            v-tooltip="'Поиск'"
          />
          <Button
            :disabled="isSearcDisables"
            @click="removeFilter"
            icon="pi pi-times"
            class="p-button"
            v-tooltip="'Сбросить фильтр'"
          />
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
export default {
  name: "FilterFromTo",
  props: {
    placeholderName: {
      type: String,
      required: true,
    },
    valueFrom: {
      type: Number,
    },
    valueTo: {
      type: Number,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSearcDisables: true,
      vTo: this.valueTo,
      vFrom: this.valueFrom,
    };
  },
  methods: {
    removeFilter() {
      this.$emit("update:valueFrom", "");
      this.$emit("update:valueTo", "");
      this.vTo = "";
      this.vFrom = "";
    },
    isSearcDisabled() {
      if (this.vTo || this.vFrom) {
        this.isSearcDisables = false;
      } else {
        this.isSearcDisables = true;
      }
      if (!this.vTo && !this.vFrom) {
        this.$emit("update:valueFrom", "");
        this.$emit("update:valueTo", "");
      }
    },
    showValue() {
      this.$emit("update:valueFrom", this.vFrom ? this.vFrom : null);
      this.$emit("update:valueTo", this.vTo ? this.vTo : null);
      // this.toggle();
    },
    toggle(event) {
      this.$refs.filters.toggle(event);
    },
  },
  created() {
    this.value ? (this.isSearcDisables = false) : (this.isSearcDisables = true);
  },
  computed: {
    valueFrom: {
      get() {
        return this.valueFrom;
      },
      set(value) {
        this.$emit("update:valueFrom", value);
      },
    },
    valueTo: {
      get() {
        return this.valueTo;
      },
      set(value) {
        this.$emit("update:valueTo", value);
      },
    },
  },
  watch: {
    valueTrn(value) {
      value.length === 0 ? (this.value = null) : null;
    },
  },

  // mounted() {
  //   this.isSearcDisabled();
  // },
};
</script>

<style lang="scss">
.fil-btn {
  .pi.pi-filter.p-button-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    &::after {
      content: "";
      position: absolute;
      margin-top: -4px;
      margin-right: -4px;
      width: 8px;
      height: 8px;
      background: var(--base);
      border-radius: 50%;
    }
  }
}
</style>